function loadLottieAnimation(className, jsonPath, autoplay = false) {
    // Select all elements with the specified class
    document.querySelectorAll(`.${className}`).forEach(function(webcontainer) {
        if (webcontainer) {
            // Fetch the animation data from the JSON file
            fetch(jsonPath)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })
                .then(animationData => {
                    var params = {
                        container: webcontainer, // Use the webcontainer class element as container
                        renderer: 'svg',
                        loop: true,
                        autoplay: autoplay,
                        animationData: animationData
                    };
                    
                    var anim = lottie.loadAnimation(params);

                    // Store animation in the element for later access
                    webcontainer.animInstance = anim;

                    // Select the sibling or parent element with the class .js-pointhover
                    var container = webcontainer.closest('.js-pointhover') || webcontainer.querySelector('.js-pointhover');

                    if (container) {
                        container.addEventListener('mouseenter', function() {
                            anim.goToAndPlay(0); // Play the animation from the start
                        });

                        container.addEventListener('mouseleave', function() {
                            anim.stop(); // Stop the animation when not hovering
                        });
                    } else {
                        console.error('Content container not found for', webcontainer);
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        } else {
            console.error('Web container not found');
        }
    });
}

// Load animations for each class
loadLottieAnimation('webvisit', './assets/lotties/web-visitor.json');
loadLottieAnimation('advertisingcall', './assets/lotties/advertisingcall.json');
loadLottieAnimation('medicaltourism', './assets/lotties/medicaltourism.json');
loadLottieAnimation('appointmentreminder', './assets/lotties/appointmentreminder.json');
loadLottieAnimation('photoarc', './assets/lotties/photoarc.json');
loadLottieAnimation('predictveimg', './assets/lotties/predictveimg.json');
loadLottieAnimation('rescheduleappoint', './assets/lotties/rescheduleappoint.json');
loadLottieAnimation('appointmentmanagment', './assets/lotties/appointmentmanagment.json');
loadLottieAnimation('questionansware', './assets/lotties/questionansware.json');
loadLottieAnimation('futuredevelopment', './assets/lotties/futuredevelopment.json');
loadLottieAnimation('aisoftware', './assets/lotties/aisoftware.json');
loadLottieAnimation('appointmentlottie', './assets/lotties/appointmentlottie.json', true);
loadLottieAnimation('tourism-coordination', './assets/lotties/tourism-coordination.json', true);

function randomTwickAnimation() {
    // Select all elements with the class .o-benefits__pointimagepart
    const elements = document.querySelectorAll('.o-benefits__pointimagepart');
    
    // Filter elements to only those inside an active .o-tab__content
    const activeElements = Array.from(elements).filter(element => {
        return element.closest('.o-tab__content.active');
    });
    
    if (activeElements.length > 0) {
        // Select a random element from the active elements
        const randomElement = activeElements[Math.floor(Math.random() * activeElements.length)];
        
        if (randomElement.animInstance) {
            // Play the animation
            randomElement.animInstance.goToAndPlay(0);
            
            // Stop the animation after 5 seconds
            setTimeout(() => {
                randomElement.animInstance.stop();
            }, 5000);
        }
    }
}

setInterval(randomTwickAnimation, 1000);

